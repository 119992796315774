
h4.lbl-title-inner {
    font-size: 16px;
    font-weight: 800;
  }
  .row-item {
    background: #f2f2f2;
    margin-top: 5px;
    padding: 9px 14px;
    border-radius: 5px;
  }
  .title-video {
  
    padding: 20px;
    font-weight: 700;
  }
  video.data-videoBox {
    width: 95%;
    border: 1px solid #e5e5e5;
  }
  .pdright30{
    padding-right: 30px;
  }
  .section-blocks {
    border-bottom: 1px solid #f6f6f6;
    padding: 15px 5px;
  }
  button.btn-linear-text {
    background: transparent;
    border: 0px;
    color: #2f64a2;
  }
  iframe.data-videoBox {
    width: 88%;
    height: 460px;
  }
  .frame-video-display{
    text-align: center;
  }
  .pd10{
    padding: 10px;
  }
  .form-group.sl-mset {
    width: 75%;
  }
  .floatr {
    float: right;
  }
  .floatl {
    float: left;
  }
  .fl-position {
    position: absolute;
    right: 25px;
    margin-top: -110px;
    font-size: 11px;
  }
  .btn-gray {
    background: #bfbfbf;
  }
  .circle-col {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  .banner-image {
    width: 97%;
    height: 370px;
    background-position: center;
  }
  .img-profile-single{
    width: 90px;
    height: 90px;
    background-position: center;
    background-size: cover;
    border-radius: 40px;
    margin: auto;
  }
  .lbl-title-lspeak {
    font-weight: 500;
}
  .txt-content {
    font-size: 9px;
    text-align: center;
    padding: 5px;
  }
  .list-speakers{
    overflow: auto;
    white-space: nowrap;
  }
  .list-speakers .img-part-items {
    display: inline-block;
    text-align: center;
    padding: 14px;
    text-decoration: none;
  }
  .form-group.agenda-radios label {
    margin-right: 20px;
  }
  .agenda-list li {
    background: #efefef;
    padding: 8px 12px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
  .time-top {
    font-weight: 300;
  }
  .ms-item-block{
    position: relative;
    cursor: pointer;
  }
  .contact-inky {
    font-size: 7px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    padding: 4px;
  }
  .img-speak-v {
    background-size: cover;
    border-radius: 50%;
    height: 50px;
    margin: auto;
    width: 50px;
    background-position: center;
  }
  .txt-speak-tit{
    text-align: center;
  }
  .sp-work {
    width: 70px;
    text-align: center;
    position: relative;
    display: inline-block;
    padding-bottom: 6px;
  }
  label.spk-lb2 {
    margin-left: 20px;
    margin-top: -18px !important;
    display: block;
  }
  .chkbx-small {
    font-size: 10px;
    padding: 3px;
    border: 1px dashed #f1efef;
    margin-bottom: 3px;
  }
  .chkbx-small input[type=checkbox] {
    margin-top: 7px;
  }
  .scroll-phase-list {
    height: 500px;
    overflow: scroll;
    padding: 5px;
  }
  .scroll-phase-list {
    height: 510px;
    overflow: scroll;
    padding: 5px 9px;
    background-color: #fdfdfd;
  }
  .form-group.fr-dt-item {
    padding: 1px 10px;
  }
  .fl-mid-block {
    border-radius: 10px;
    border: 1px dotted #d7d7d7;
    background: #fafafa;
  }
  p.opt {
    padding-left: 15px;
    font-size: 10px;
    padding-top: 15px;
  }
  .list-flight-options p {
    padding: 2px;
    margin: 1px;
    font-size: 11px;
    background: #d9f6ca;
  }
  .list-flight-options p span {
    margin-right: 15px;
  }
  .block-form-sect {
    padding: 4px 6px;
    border: 1px dotted #ededed;
    border-radius: 7px;
    margin-bottom: 10px;
  }

  .in-container {
    padding: 10px 0px;
  }
  .in-container {
    padding-top: 10px;
  }
  .form-group.frm-button {
    margin-top: 15px;
    margin-bottom: 20px;
  }

  .bTitle2 {
    font-size: 18px;
  }