
.reg-header {
    padding: 10px 50px;
    display: flex;
    justify-content: space-between;
}
img.logo-one {
    width: 170px;
}
.btn-rescure-login {
    background: #FF6600;
    padding: 10px 20px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
    font-weight: 800;
}
.main-login-data {
    padding: 45px 60px;
    display: flex;
}
.frm-area {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    width: 400px;
    margin-top: 20%;
    margin-left: 20%;
}
.data-image-block {
    margin-left: 15%;
}
.log-start {
    height: 100vh;
}
.reg-left-image{
    background-image: url('https://images.pexels.com/photos/3850689/pexels-photo-3850689.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
    width: 40%;
    height: 100vh;
}
.reg-right-form-data {
    padding: 20px;
    width: 50%;
}
.head-right-host {
    text-align: right;
}
.flexregister {
    display: flex;
}
.reg-form {
    padding: 75px 40px;
}
.frm-area form {
    padding-top: 20px;
    padding-bottom: 20px;
}
img.logo-on-display {
    width: 530px;
    margin-top: 70px;
}
.btn-rescure-login:hover {
    background: #3e2f09;
    color: #f0f0f0;
}