.side-dash {
    background: linear-gradient(135deg,#4a3f27 0,#261f1e 60%);
    color: #e9e9e9;

}
.slide-head-logo {
    text-align: center;
    padding: 15px;
}
img.logo-one-inside {
    width: 80%;
}
ul.sidebarList li{
    margin-top: 5px;
    margin-bottom: 10px;
}
.sidebarWrapper {
    padding: 10px;
}
.slim-side{
    width: 100px;
}
.full-side {
    width: 250px;
}
.sidebarMenu ul li a:hover{
    background-color: #664028;
    color: #e5e5e5;
}
.sidebarTitle.logo-mobile {
    display: none;
}
.alerter {
  padding:10px
}
span.name-Item {
  padding-left: 10px;
}
span.sd-icon {
    padding: 10px;
}
h3.sidebarTitle.logo-spark {
    padding: 20px;
}
.sidebarMenu ul {
    list-style: none;
    padding: 0px;
}
.sidebarMenu ul li a {
    color: #fff;
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    display: block;
    padding: 12px 10px;
    margin-bottom: 1px;
}
@media only screen and (max-width: 600px) {
    .full-side {
        width: 60px;
    }
    span.name-Item {
        display: none;
    }
    h3.sidebarTitle.logo-spark {
        opacity: 0;
    }
  }