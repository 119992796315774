body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input.form-control , input.form-control:focus, textarea.form-control , textarea.form-control:focus, select.form-control {
  border: 0px;
  background: #e5e5e5;
}
.p-button {
  background: #742e00;
  border: 1px solid #dc5801;
}
.btn-full {
  width: 100%;
}
.dashingboard {
  background-color: rgb(255 252 252);
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
.full-side {
  width: 250px;
}
.main-dash-content {
  width: 100%;
}
.main-content-arena {
  padding: 30px;
}
.p-dialog-header {
  background: #a9111f !important;
  background: linear-gradient(45deg, #f60, #1e1f26) !important;
  color: #fff !important;
}
.modal-body-content {
  padding: 15px 10px;
  position: relative;
}
.btsm1 {
  font-size: 10px;
}
.btn-rescure{
  background-color:#FF6600; 
  color:#fff;
  font-weight: 800;
  border: none;
}
.btn-rescure:hover{
  background-color: #8c5111!important;
}
.row-box {
  padding: 15px 0px;
}
.listing-search-items {
  position: fixed;
  background: #fff;
  width: 350px;
  border: 1px solid #c9c9c9;
  margin-top: -13px;
}
.listing-search-items ul li {
  padding: 5px;
  cursor: pointer;
  
}
.listing-search-items ul li:hover {
  background-color: rgb(232, 232, 232);
  
}
.no-style {
  list-style-type: none;
  padding: 0px;
}
.flexme {
  display: flex;
}
.flexwidth50 {
  width: 50%;
}
.flexwidth75 {
  width:75%;
}
.flexwidth33 {
  width: 33%;
}
.flexwidth90 {
  width: 90%;
}
.wide70 {
  width: 70%;
}
.flex_sub60 {
  width: 60%;
}
.flex_sub40 {
  width: 40%;
}
span.found-ic.f-uptrue {
  background: #20bc30;
  padding: 10px;
  color: #fff;
}
span.found-ic.f-upfalse {
  background: #bc2020;
  padding: 10px;
  color: #fff;
}
.pod-creat-pharm {
  padding-top: 8px;
  padding-left: 15px;
}
.pdlf10{
  padding-left:10px;
}
.pd10{
  padding:10px;
}
.mgtop10{
  margin-top: 10px;
}
.mgtop20{
  margin-top: 20px;
}
.mgtop30{
  margin-top: 30px;
}
h2.lbl-title-page {
  color: #868899;
  font-size: 18px;
}
.showcontent input.form-control {
  font-weight: 300;
  background: #fff;
  pointer-events: none;
  padding: 0px;
}
.view-gifts-list {
  background: #fbfafa;
  padding: 10px;
  height: 160px;
  overflow: scroll;
  border-radius: 10px;
  margin-bottom: 20px;
}
.row.rs-card {
  margin: 0px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 25px;
  margin-bottom: 20px;
}
/* Progress Bar Start */
.progress {
	background-color: #d8d8d8;
	border-radius: 20px;
	position: relative;
	margin: 15px 0;
	height: 30px;
	width: 350px;
}
.progress-done {
	background: linear-gradient(to left, #FF6600, #2e1d12);
	box-shadow: 0 3px 3px -5px #FF6600, 0 2px 5px #FF6600;
	border-radius: 20px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 0;
	opacity: 0;
	transition: 1s ease 0.3s;
}
/* Progress Bar End */
.msg-processing-item {
  text-align: center;
  padding: 15px;
  font-weight: 100;
}
li.pharm-item {
  background: #ebebeb;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1px;
  padding: 3px;
}
button.btn.btn-close-ls.posi-close {
  background: #1a1713;
  color: #fff;
}
button.btn.btn-close-ls.posi-close:hover {
  background: #432f18;
  color: #fff;
}
.pharmacies-container {
  padding: 5px;
  height: 450px;
  overflow: scroll;
}
.message-error {
  padding-top: 20px;
}
.img-source {
  position: fixed;
  left: 42%;
  top: 45%;
}
img.proc {
  width: 120px;
}
.position-center.large-focus {
  position: fixed;
  left: 50%;
}
.position-center.large-focus img.proc{
  width: 160px;
}
.img-care{
  width: 90px;
  padding: 5px;
  background: #fff;
  border: 1px solid #FF6600;
  height: 90px;
  box-shadow: 1px 2px 1px #c8c8c8;
}
tr.p-row-odd td {
  background-color: #fbfafa;
}
img.imag-boxer {
  width: 200px;
  display: block;
}
.bottom-buttons {
  text-align: right;
  padding: 20px 5px;
  margin-top: 15px;
}
.mt-list table td {
  font-size: 12px;
}
.wid230{
  width: 230px;
}
td.wcel5 {
  padding: 5px;
}
.group-form-add{
  border: 1px dashed #e1e1e1;
  margin-top: 5px;
}
.bold {
  font-weight: 700;
}
img.event-image-logo {
  width: 100px;
}
.supplier-strip {
  padding: 15px;
  height: 500px;
  overflow: scroll;
}
.item-text.contsup {
  width: 350px;
}
.item-text .head-sponsor {
  font-size: 30px;
  font-weight: 200;
}
li.pharm-item.supli {
  background: #d9f6ca;
  margin-bottom: 2px;
  padding: 6px;
  border-radius: 5px;
}
.gray-t {
  color: gray;
}
button.btn.btn-close-ls.trback.posi-close {
  background: #4a3f27;
}
.logo-ev-cont {
  background: #fff;
}
button.graygray-out {
  background: gray;
}
button.graygray-out:hover {
  background: rgb(85, 84, 84);
}
.publish-item {
  float: right;
  padding-right: 31px;
}
.publish-item {
  float: right;
  padding-right: 31px;
  width: 300px;
}
.publish-status {
  float: left;
  padding: 8px;
  font-size: 10px;
  margin-top: 4px;
  text-align: center;
  margin-right: 13px;
}
.publish-status.greenify-event{
  background: #daffda;
  border: 1px solid #5ffd5f;
}
.publish-status.redify-event{
  background: #ffe1da;
  border: 1px solid #fd5f5f;
}
span.txtTab {
  color: #FF6600;
  font-size: 13px;
  cursor: pointer;
}
.form-content.event-srs input.form-control.wide100 {
  width: 95%;
}
.noti-ct {
  font-size: 60px;
  font-weight: 600;
  color: #045b1a;
}
.kabong {
  background: #aeffaec2;
  font-size: 10px;
  color: #0a4d0a;
  padding: 6px 10px;
  display: inline-block;
}
.stitle {
  font-size: 16px;
  padding-top: 10px;
}
.rsvp {
  font-size: 10px;
  text-align: center;
  padding: 5px;
}
.rsvp.rsvtrue{
  background: #71ff0042;
}
.rsvp.rsvfalse{
  background: #ff6e0042;
}
.tt-rs {
  font-weight: 700;
  font-size: 13px;
}
.tt-ans {
  margin-bottom: 10px;
}
.col-block {
  padding: 8px;
  padding-top: 10px;
  border: 1px solid #f0f0f0;
  margin-bottom: 25px;
  height: 220px;
  border-radius: 9px;
}

.details-container {
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #adadad;
}
.rsvp-box {
  background: #ffa50014;
  padding: 10px;
  border-radius: 15px;
}
.download-short {
  font-size: 12px;
  cursor: pointer;
  color: #f60;
  padding: 10px 5px;
}

tr.unse-processed.p-row-odd td {
  background: #fdf6ea;
}
tr.unse-processed td {
  background: #fffaf3;
}
.listing-container.model-in-table table tr td {
  font-size: 12px;
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #412424;
  border-color: #412424;
}
.bs-box {
  border: 1px solid #e1e1e1;
  padding: 10px;
}
button.small-close {
  font-size: 10px;
  border-radius: 18px;
  padding: 6px 10px;
}
.visittop {
  padding: 3px 0px;
  border-top: 1px solid #f4f4f4;
}
.griddy-profile {
  border: 1px solid #dedede;
  border-radius: 6px;
  padding: 5px;
}
.img-part-items {
  margin-bottom: 30px;
}
/* CSS for the accordion */
.accordion-mev {
  margin-top: 10px;
}
.accordion-input {
  display: none;
}
.accordion-label {
  display: block;
  cursor: pointer;
  font-weight: bold;
  background: linear-gradient(135deg, #4a3f27 0, #261f1e 60%);
  color: #e9e9e9;
  padding: 10px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  font-size: 14px;
}
.accordion-content {
  display: none;
}
.accordion-input:checked + .accordion-label + .accordion-content {
  display: block;
}
/* CSS for the accordion END */
img.img-comp {
  height: 50px;
}
img.prize-processing {
 
  width: 200px;
}
.congrats-pop-window {
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  padding: 15px;
  background: #efefef;
}
.box-rep {
  border: 1px solid #ebebeb;
  text-align: center;
  padding: 40px;
  margin-top: 30px;
}
.banner-sub-slide {
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: contain;
  margin-bottom: 10px;
}
.mgtop10{
  margin-top: 10px;
}