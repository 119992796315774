@media screen and (max-width: 1350px) {

}
@media screen and (max-width: 1250px) {

}
@media screen and (max-width: 950px) {

}
@media screen and (max-width: 500px) {
    span.name-Item {
        display: none;
    }
    .full-side {
        width: 70px !important;
    }
    img.logo-on-dashboard {
        width: 100%;
        position: relative;
      }

}